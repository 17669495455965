import React, { lazy, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Cookie from 'js-cookie'
import { useAuth } from './context/AuthContext'
const UserLayout = lazy(() =>
  wait(1000).then(() => import('./layouts/UserLayout')),
)
const AdminLayout = lazy(() =>
  wait(1000).then(() => import('./layouts/AdminLayout')),
)
const RegisterSupplier = lazy(() =>
  wait(1000).then(() => import('./components/Suppliers/RegisterSupplier')),
)
const SupplierBasicDetail = lazy(() =>
  wait(1000).then(() => import('./components/Suppliers/SupplierBasicDetail')),
)
const Supplierslogin = lazy(() =>
  wait(1000).then(() => import('./components/Login/Supplierlogin')),
)
const Suppliersregister = lazy(() =>
  wait(1000).then(() => import('./components/Login/Supplierregister')),
)
const Supplierstepform = lazy(() =>
  wait(1000).then(() =>
    import('./components/Supplierdashbord/Stepform/Supplierstepform'),
  ),
)
const EmailVerify = lazy(() =>
  wait(1000).then(() => import('./components/EmailVerify/EmailVerify')),
)
const VerifiedEmail = lazy(() =>
  wait(1000).then(() => import('./components/VerificationEmail/VerifiedEmail')),
)
const Login = lazy(() =>
  wait(1000).then(() => import('./components/Login/Login')),
)
const SuspenseLoader = lazy(() =>
  wait(1000).then(() => import('./common/SuspenseLoader')),
)
const Dashboard = lazy(() =>
  wait(1000).then(() => import('./components/Dashboard')),
)
const Permissions = lazy(() =>
  wait(1000).then(() =>
    import('./components/AccessManagement/Permissions/Permissions'),
  ),
)
const Roles = lazy(() =>
  wait(1000).then(() => import('./components/AccessManagement/Roles/Roles')),
)
const RolesAdd = lazy(() =>
  wait(1000).then(() => import('./components/AccessManagement/Roles/RolesAdd')),
)
const RolesEdit = lazy(() =>
  wait(1000).then(() => import('./components/AccessManagement/Roles/RolesAdd')),
)
const Users = lazy(() =>
  wait(1000).then(() => import('./components/AccessManagement/Users/Users')),
)
const UsersAdd = lazy(() =>
  wait(1000).then(() => import('./components/AccessManagement/Users/UsersAdd')),
)
const Suppliers = lazy(() =>
  wait(1000).then(() => import('./components/Suppliers/Suppliers')),
)
const EditSupplier = lazy(() =>
  wait(1000).then(() => import('./components/Suppliers/EditSupplier')),
)
const Affiliate = lazy(() =>
  wait(1000).then(() => import('./components/Affiliate/Affiliate')),
)
const AddAffiliate = lazy(() =>
  wait(1000).then(() => import('./components/Affiliate/AddAffiliate')),
)
const TicketCategory = lazy(() =>
  wait(1000).then(() => import('./components/TicketCategory/TicketCategory')),
)
const AddEditTicketCategories = lazy(() =>
  wait(1000).then(() =>
    import('./components/TicketCategory/AddEditTicketCategories'),
  ),
)
const TicketPriority = lazy(() =>
  wait(1000).then(() => import('./components/TicketPriority/TicketPriority')),
)
const AddEditTicketPriorities = lazy(() =>
  wait(1000).then(() =>
    import('./components/TicketPriority/AddEditTicketPriorities'),
  ),
)
const Marketplace = lazy(() =>
  wait(1000).then(() => import('./components/Marketplace/Marketplace')),
)
const MarketplaceAdd = lazy(() =>
  wait(1000).then(() => import('./components/Marketplace/MarketplaceAdd')),
)
const ShippingCarriers = lazy(() =>
  wait(1000).then(() =>
    import('./components/ShippingCarriers/ShippingCarriers'),
  ),
)
const ShippingCarriersAdd = lazy(() =>
  wait(1000).then(() =>
    import('./components/ShippingCarriers/ShippingCarriersAdd'),
  ),
)
const Brands = lazy(() =>
  wait(1000).then(() => import('./components/Brands/Brands')),
)
const Category = lazy(() =>
  wait(1000).then(() => import('./components/Category/Category')),
)
const Country = lazy(() =>
  wait(1000).then(() => import('./components/Country/Country')),
)
const State = lazy(() =>
  wait(1000).then(() => import('./components/State/State')),
)
const Cities = lazy(() =>
  wait(1000).then(() => import('./components/Cities/Cities')),
)
const Fulfillment = lazy(() =>
  wait(1000).then(() =>
    import('./components/AccessManagement/Fulfillment/User'),
  ),
)
const FulfillmentAdd = lazy(() =>
  wait(1000).then(() =>
    import('./components/AccessManagement/Fulfillment/UsersAdd'),
  ),
)
const ShippingServices = lazy(() =>
  wait(1000).then(() =>
    import('./components/ShippingServices/ShippingServices'),
  ),
)
const ShippingServicesAdd = lazy(() =>
  wait(1000).then(() =>
    import('./components/ShippingServices/ShippingServicesAdd'),
  ),
)
const Products = lazy(() =>
  wait(1000).then(() => import('./components/Products/Products')),
)
const AddProduct = lazy(() =>
  wait(1000).then(() => import('./components/Products/AddProduct')),
)
const Orders = lazy(() =>
  wait(1000).then(() => import('./components/Orders/Orders')),
)
const ManageOrders = lazy(() =>
  wait(1000).then(() => import('./components/Orders/ManageOrders')),
)

const PendingOrder = lazy(() =>
  wait(1000).then(() => import('./components/Orders/pendingOrder')),
)
const UnShippedOrder = lazy(() =>
  wait(1000).then(() => import('./components/Orders/unShippedOrder')),
)
const CancelledOrder = lazy(() =>
  wait(1000).then(() => import('./components/Orders/CancelledOrder')),
)
const SentOrder = lazy(() =>
  wait(1000).then(() => import('./components/Orders/SentOrder')),
)
const OrdersDetail = lazy(() =>
  wait(1000).then(() => import('./components/Orders/OrdersDetail')),
)
const Warehouses = lazy(() =>
  wait(1000).then(() => import('./components/Warehouses/Warehouses')),
)
const Suppliersdashbord = lazy(() =>
  wait(1000).then(() =>
    import('./components/Supplierdashbord/SuppliersDashboard'),
  ),
)
const Suppliersproducts = lazy(() =>
  wait(1000).then(() => import('./components/Supplierdashbord/Product')),
)
const Productimage = lazy(() =>
  wait(1000).then(() => import('./components/Supplierdashbord/Productimage')),
)
const Productdetails = lazy(() =>
  wait(1000).then(() => import('./components/Supplierdashbord/Productdetails')),
)
const Warehousess = lazy(() =>
  wait(1000).then(() =>
    import('./components/Supplierdashbord/SupplierWarehouses'),
  ),
)
const Inventory = lazy(() =>
  wait(1000).then(() => import('./components/Supplierdashbord/Inventory')),
)
const Territories_Retialers = lazy(() =>
  wait(1000).then(() =>
    import('./components/Supplierdashbord/TerritoriesRetialers'),
  ),
)
const WarehousesAdd = lazy(() =>
  wait(1000).then(() => import('./components/Warehouses/WarehousesAdd')),
)

const FulFillments = lazy(() =>
  wait(1000).then(() => import('./components/Supplierdashbord/FulFillments')),
)

const Integrations = lazy(() =>
  wait(1000).then(() => import('./components/Integrations/Integrations')),
)
const AddEditIntegration = lazy(() =>
  wait(1000).then(() => import('./components/Integrations/AddEditIntegration')),
)
const Promotions = lazy(() =>
  wait(1000).then(() => import('./components/Promotions/Promotions')),
)
const AddEditPromotion = lazy(() =>
  wait(1000).then(() => import('./components/Promotions/AddEditPromotion')),
)
const MarketPlaceInventory = lazy(() =>
  wait(1000).then(() =>
    import('./components/MarketPlaceInventory/MarketPlaceInventory'),
  ),
)
const AddEditMarketPlaceInventory = lazy(() =>
  wait(1000).then(() =>
    import('./components/MarketPlaceInventory/AddEditMarketPlaceInventory'),
  ),
)
const Support = lazy(() =>
  wait(1000).then(() =>
    import('./components/Supplierdashbord/Support/Support'),
  ),
)
const AddEditTicket = lazy(() =>
  wait(1000).then(() =>
    import('./components/Supplierdashbord/Support/AddEditTicket'),
  ),
)

const ProtectedRoute = ({ element, role }) => {
  const { user, isImpersonating } = useAuth()
  return user?.user_type === role || isImpersonating ? (
    element
  ) : (
    <Navigate to="/login" />
  )
}
const SupplierStepRoute = ({ element }) => {
  const supplierId = localStorage.getItem('supplier_id')
  const onboardingStatus = localStorage.getItem('onboardingStatus')
  return supplierId && onboardingStatus === '0' ? (
    element
  ) : (
    <Navigate to="/login" />
  )
}
function AppRoutes() {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Routes>
        {/* Admin Routes */}
        <Route
          path="/*"
          element={<ProtectedRoute role="admin" element={<AdminLayout />} />}
        >
          <Route path="brands" element={<Brands />} />
          <Route exact path="" element={<Dashboard />} />
          <Route exact path="home" element={<Dashboard />} />
          <Route exact path="permissions" element={<Permissions />} />
          <Route exact path="roles" element={<Roles />} />
          <Route exact path="roles/add" element={<RolesAdd />} />
          <Route exact path="roles/edit/:id" element={<RolesEdit />} />
          <Route exact path="users" element={<Users />} />
          <Route exact path="users/add" element={<UsersAdd />} />
          <Route exact path="users/edit/:id" element={<UsersAdd />} />
          <Route exact path="fulfillment-users" element={<Fulfillment />} />
          <Route
            exact
            path="fulfillment-users/add"
            element={<FulfillmentAdd />}
          />
          <Route
            exact
            path="fulfillment-users/edit/:id"
            element={<FulfillmentAdd />}
          />
          <Route exact path="suppliers/:status" element={<Suppliers />} />
          <Route exact path="suppliers/add" element={<EditSupplier />} />
          <Route exact path="suppliers/edit/:id" element={<EditSupplier />} />
          <Route exact path="affiliate" element={<Affiliate />} />
          <Route exact path="affiliate/add" element={<AddAffiliate />} />
          <Route exact path="affiliate/edit/:id" element={<AddAffiliate />} />
          <Route exact path="ticket-priority" element={<TicketPriority />} />
          <Route
            exact
            path="ticket-priority/add"
            element={<AddEditTicketPriorities />}
          />
          <Route
            exact
            path="ticket-priority/edit/:id"
            element={<AddEditTicketPriorities />}
          />
          <Route exact path="ticket-category" element={<TicketCategory />} />
          <Route
            exact
            path="ticket-category/add"
            element={<AddEditTicketCategories />}
          />
          <Route
            exact
            path="ticket-category/edit/:id"
            element={<AddEditTicketCategories />}
          />
          <Route exact path="marketplace" element={<Marketplace />} />
          <Route exact path="marketplace/add" element={<MarketplaceAdd />} />
          <Route
            exact
            path="marketplace/edit/:id"
            element={<MarketplaceAdd />}
          />
          <Route
            exact
            path="shipping-carriers"
            element={<ShippingCarriers />}
          />
          <Route
            exact
            path="shipping-carriers/add"
            element={<ShippingCarriersAdd />}
          />
          <Route
            exact
            path="shipping-carriers/edit/:id"
            element={<ShippingCarriersAdd />}
          />
          <Route
            exact
            path="shipping-services"
            element={<ShippingServices />}
          />
          <Route
            exact
            path="shipping-services/add"
            element={<ShippingServicesAdd />}
          />
          <Route
            exact
            path="shipping-services/edit/:id"
            element={<ShippingServicesAdd />}
          />
          <Route exact path="products" element={<Products />} />
          <Route exact path="product/add" element={<AddProduct />} />
          <Route exact path="product/edit/:id" element={<AddProduct />} />
          <Route exact path="category" element={<Category />} />
          <Route exact path="country" element={<Country />} />
          <Route exact path="state" element={<State />} />
          <Route exact path="Cities" element={<Cities />} />
          <Route exact path="warehouses" element={<Warehouses />} />
          <Route exact path="warehouses/add" element={<WarehousesAdd />} />
          <Route exact path="integrations" element={<Integrations />} />
          <Route
            exact
            path="integrations/add"
            element={<AddEditIntegration />}
          />
          <Route
            exact
            path="integrations/edit/:id"
            element={<AddEditIntegration />}
          />
          <Route exact path="promotions" element={<Promotions />} />
          <Route exact path="promotions/add" element={<AddEditPromotion />} />
          <Route
            exact
            path="promotions/edit/:id"
            element={<AddEditPromotion />}
          />
          <Route
            exact
            path="marketplace-inventory"
            element={<MarketPlaceInventory />}
          />
          <Route
            exact
            path="marketplace-inventory/add"
            element={<AddEditMarketPlaceInventory />}
          />
          <Route
            exact
            path="marketplace-inventory/edit/:id"
            element={<AddEditMarketPlaceInventory />}
          />
          {/* <Route exact path="warehouses" element={<Warehouses />} /> */}
          <Route exact path="orders" element={<Orders />} />
          <Route exact path="manageorders" element={<ManageOrders />} />
          <Route exact path="orders/pending" element={<PendingOrder />} />
          <Route exact path="orders/unshipped" element={<UnShippedOrder />} />
          <Route exact path="orders/cancelled" element={<CancelledOrder />} />
          <Route exact path="orders/sent" element={<SentOrder />} />

          <Route exact path="orders/:order_id" element={<OrdersDetail />} />
        </Route>

        {/* Supplier Routes */}
        <Route
          path="/supplier/*"
          element={<ProtectedRoute role="supplier" element={<UserLayout />} />}
        >
          <Route exact path="" element={<Suppliersdashbord />} />
          <Route exact path="dashboard" element={<Suppliersdashbord />} />
          <Route exact path="products" element={<Suppliersproducts />} />
          <Route exact path="productimage" element={<Productimage />} />
          <Route exact path="support" element={<Support />} />
          <Route exact path="support/addticket" element={<AddEditTicket />} />
          <Route
            exact
            path="support/editticket/:id"
            element={<AddEditTicket />}
          />
          <Route exact path="productdetails" element={<Productdetails />} />
          <Route exact path="fulfillments" element={<FulFillments />} />

          <Route exact path="warehouses" element={<Warehousess />} />
          <Route exact path="inventory" element={<Inventory />} />
          <Route
            exact
            path="Territories-Retialers"
            element={<Territories_Retialers />}
          />
        </Route>
        {/* Default redirect */}
        <Route path="/register" element={<RegisterSupplier />} />
        <Route path="/supplierbasicinfo" element={<SupplierBasicDetail />} />
        {/* <Route path="/" element={<Navigate to="/login" />} /> */}
        <Route path="/login" element={<Supplierslogin />} />
        <Route path="/supplier/register" element={<Suppliersregister />} />
        <Route
          path="/supplier/on-boarding"
          element={<SupplierStepRoute element={<Supplierstepform />} />}
        />
        <Route path="/verify-email/:token" element={<EmailVerify />} />
        <Route path="/verificationemail" element={<VerifiedEmail />} />
      </Routes>
    </Suspense>
  )
}

function wait(time) {
  return new Promise(resolve => {
    setTimeout(resolve, time)
  })
}

export default AppRoutes
